import React, {FC, useEffect, useState} from "react";
import {
  Header,
  Placeholder,
  Wrapper,
  Label,
  Arrow,
  Body,
  InnerBody,
  Line,
  ListItem,
  TipWrapperContainer, TipWrapper, TipTail
} from './style';
import ArrowSVG from './arrow.svg';
const LINE_SPLIT_LENGTH = 20;
const LINE_SPLIT_HEADER_LENGTH = 13;
type Option = {
  label: string,
  value: string
}

export type AccordeonProps = {
  options: Array<Option>;
  value?: string;
  onChange: (value: string) => void;
  onHover: (x: string, y: string) => void;
  onMouseOut: () => void;
  placeholder: string;
  isOpen: boolean;
  onOpen: () => void;
  numRows?: number;
  headerHeight?: number;
  dataTestId?: string
}

const Accordeon: FC<AccordeonProps> = ({value, onOpen,  onChange, options, placeholder, isOpen, numRows=5, headerHeight=30, dataTestId= 'no'}) => {
  const [currentValue, setCurrentValue] = useState<Option>({value: '', label: ''});
  const [_isOpen, setIsOpen] = useState(false);
  const [tip, setTip] = useState({
    visible: false,
    x: 0,
    y: 0,
    label: ''
  });

  useEffect(() => {
    setIsOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (!value) {
      return;
    }
    const option = options.filter(i => i.value === value)[0];

    if (!option) {
      return;
    }

    setCurrentValue(option);
  }, [value, options]);

  const renderString = (v: string, length: number) => v.length > length ? `${v.substr(0, length)}...` : v;

  const handleChange = (item: Option) => {
    onChange(item.value);
    setCurrentValue(item);
    setIsOpen(false);
  }

  const handleMouseOver = (event: any, _label: string) => {
    event.stopPropagation();
    if (_label.length <= LINE_SPLIT_LENGTH ) {
      return;
    }

    if (event.target.tagName === 'P') {
      setTip({...tip, visible: true})
      return;
    }

    const element = event.target;
    const bound = element.getBoundingClientRect();
    setTip({
      x: bound.right + 10,
      y: bound.bottom - 35 ,
      visible: true,
      label: _label
    })
  }

  const handleMouseOut = () => {
    setTip({
      ...tip,
      visible: false,
    })
  }

  return (
    <Wrapper isOpen={_isOpen} data-is-open={_isOpen}>
      <Header onClick={() => {
        !_isOpen && onOpen();
        setIsOpen(!_isOpen);

      }} headerHeight={headerHeight} data-test-id={`${dataTestId}-header`}>
        {Boolean(currentValue.value.length) ? (<Label data-test-id={`${dataTestId}-header-label`}>{renderString(currentValue.label, LINE_SPLIT_HEADER_LENGTH)}</Label>) : (<Placeholder data-test-id={`${dataTestId}-header-label`}>{placeholder}</Placeholder>)}
        <Arrow src={ArrowSVG} isOpen={!_isOpen} data-test-id={`${dataTestId}-header-arrow`}/>
      </Header>
      {_isOpen && (<Line/>)}
      <Body isOpen={_isOpen} data-test-id={`${dataTestId}-body`}>
        <InnerBody numRows={numRows} data-test-id={`${dataTestId}-body-inner`}>
          {Boolean(options.length) && options.map((item, index) => {
            return (<ListItem
              key={item.value + index}
              onClick={() => handleChange(item)}
              isActive={item.value === currentValue.value}
              onMouseOver={(e) => handleMouseOver(e, item.label)}
              onMouseOut={handleMouseOut}
              data-test-id={`${dataTestId}-option-${index}`}
            >
              <p>{renderString(item.label, LINE_SPLIT_LENGTH)}</p>
            </ListItem>)
          })}
        </InnerBody>
      </Body>
      {tip.visible && (
        <TipWrapperContainer style={{left: tip.x, top: tip.y}} data-test-id={`${dataTestId}-option-tip-wrapper`}>
          <TipWrapper data-test-id={`${dataTestId}-option-tip`}>
            {tip.label}
            <TipTail/>
          </TipWrapper>
        </TipWrapperContainer>
      )}
    </Wrapper>
  )
}

export default Accordeon;