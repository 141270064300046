import React, { FC } from 'react';

import { Button } from '../../../../Button';
import { TModalButton } from '../Modal/Modal';
import Styled from './styles';

export type TModalFooterConfigProps = {
  successButton?: TModalButton;
  denyButton?: TModalButton;
  closeModalHandler?: () => void;
  dataTestId?: string;
};

export const ModalFooter: FC<TModalFooterConfigProps> = ({
  successButton,
  denyButton,
  closeModalHandler,
  dataTestId,
}) => {
  const denyButtonHandler = async (): Promise<void> => {
    await denyButton?.handler?.();
    if (closeModalHandler) closeModalHandler();
  };

  const successButtonHandler = async (): Promise<void> => {
    await successButton?.handler?.();
    if (closeModalHandler) closeModalHandler();
  };

  return (
    <Styled.ButtonActionWrapper>
      {denyButton && (
        <Button
          color={denyButton?.color || 'default'}
          type={'button'}
          disabled={denyButton?.disabled}
          dataTestId={
            denyButton.dataTestId
              ? denyButton.dataTestId
              : `${dataTestId}-modal-deny-button`
          }
          onClick={denyButtonHandler}
        >
          {denyButton.title}
        </Button>
      )}
      {successButton && (
        <Button
          color={successButton?.color || 'primary'}
          type={'button'}
          disabled={successButton?.disabled}
          dataTestId={
            successButton.dataTestId
              ? successButton.dataTestId
              : `${dataTestId}-modal-accept-button`
          }
          onClick={successButtonHandler}
        >
          {successButton.title}
        </Button>
      )}
    </Styled.ButtonActionWrapper>
  );
};
