import React, { FC, useState, useEffect } from 'react';
import {
  Field,
  SelectBody, SelectBodyInnerWrapper,
  Value, Wrapper, Option, Error
} from './style';
import {Icon} from '../Icon';
import {Colors} from '../configs/Colors';
import {Checkbox} from '../index';

type OptionType = {
  label: string;
  value: string;
};

export type MultiSelectProps = {
  label: string;
  defaultValue?: Array<OptionType>;
  options: OptionType[];
  onChange: (v: Array<string>) => void;
  error?: string;
  dataTestId?: string;
}

const MultiSelect: FC<MultiSelectProps> = ({
                                   label,
                                   defaultValue,
                                   options,
                                   onChange,
                                   error,
    dataTestId
                                 }) => {

  const [value, setValue] = useState<Array<string>>([]);
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [isErrorShown, setIsErrorShown] = useState(false);

  useEffect(() => {
    defaultValue && setValue(defaultValue.map(item => item.value));
  }, [defaultValue]);

  useEffect(() => {
    error && setIsErrorShown(true);
  }, [error])

  const handleClick = () => {
    setIsOpened(!isOpened);
    setIsErrorShown(false);
  }

  const handleSelectOption = (item: OptionType) => {
    let result = [];
    if (isValueSelected(item.value)) {
      result = value.filter(id => id !== item.value)
    } else {
      result = [...value, item.value]
    }

    onChange && onChange(result);
    setValue(result);
  }

  const isValueSelected = (v: string) => value.filter(id => id === v).length > 0;

  const renderOptions = (options: OptionType[]) => options && options.map((item) => (
    <Option onClick={() => handleSelectOption(item)} key={`option-${item.value}`}>
      <Checkbox
        value={isValueSelected(item.value)}
        onChange={() => handleSelectOption(item)}
        label={item.label}
      />
    </Option>
  ));

  // const optionsWithoutSelected = options.filter(item => item.value !== value?.value);

  const handleOnBlur = () => {
    setIsOpened(false);
  }

  return (
    <Wrapper isErrorVisible={isErrorShown} onBlur={handleOnBlur} tabIndex={0} data-test-id={dataTestId}>
      <Field onClick={handleClick} isOpened={isOpened} >
        {
          value.length > 0
            ? <Value>{`${label}: выбрано ${value.length}`}</Value>
            : <Value>{label}</Value>
        }
        <Icon size={'small'} icon={'arrow_down'} fill={Colors.secondaryDarkGray}/>
      </Field>
      {isOpened && (
        <SelectBody>
          <SelectBodyInnerWrapper>
            {renderOptions(options)}
          </SelectBodyInnerWrapper>
        </SelectBody>
      )}
      {isErrorShown && error && (<Error>{error}</Error>)}
    </Wrapper>
  );
}

export default MultiSelect;
