import React, { FC, ReactNode, forwardRef, useRef, useEffect, useState } from 'react';
import { TooltipStyled, TooltipStyledProps } from './style';

type Place = 'top' | 'right' | 'bottom' | 'left';
type Type = 'dark' | 'success' | 'error' | 'info' | 'light';
type Effect = 'float' | 'solid';
type Wrapper = 'div' | 'span';
interface Offset {
  top?: number;
  right?: number;
  left?: number;
  bottom?: number;
}

type VoidFunc = (...args: any[]) => void;
type GetContentFunc = (toolTipStr: string) => React.ReactNode;
type GetContent = GetContentFunc | [GetContentFunc, number];

type TooltipProps = {
  id: string;
  place?: Place;
  type?: Type;
  effect?: Effect;
  html?: boolean;
  multiline?: boolean;
  delayHide?: number;
  delayShow?: number;
  delayUpdate?: number;
  border?: boolean;
  textColor?: string;
  backgroundColor?: string;
  borderColor?: string;
  arrowColor?: string;
  afterShow?: VoidFunc;
  afterHide?: VoidFunc;
  getContent?: GetContent;
  disable?: boolean;
  scrollHide?: boolean;
  wrapper?: Wrapper;
  clickable?: boolean;
  event?: string;
  eventOff?: string;
  offset?: Offset;
  children?: ReactNode;
  className?: string;
} & TooltipStyledProps

type TChildrenComponentProps = {
  dataFor: string;
  children: ReactNode;
};

const ChildrenComponent = forwardRef<HTMLDivElement, TChildrenComponentProps>(({ dataFor, children }, ref) => {  
  return (
   <div style={{ display: 'inline-flex' }} ref={ref} data-tip data-for={dataFor}>{children}</div>
  )
});

const Tooltip: FC<TooltipProps> = ({
  id,
  position = 'start',
  place,
  type = 'light',
  effect = 'solid',
  html,
  multiline,
  delayHide,
  delayShow,
  delayUpdate,
  border,
  textColor,
  backgroundColor,
  borderColor,
  arrowColor,
  afterShow,
  afterHide,
  disable,
  scrollHide,
  wrapper,
  clickable,
  event,
  eventOff,
  offset,
  children,
  getContent = () => null,
  className,
}) => {
  const ref = useRef(null);
  const [childrenComponentWidth, setChildrenComponentWidth] = useState<number>(0)

  useEffect(() => {
    // @ts-ignore
    setChildrenComponentWidth(ref.current.clientWidth)
  }, [])

  return (
    <>
      <ChildrenComponent ref={ref} dataFor={id}>{children}</ChildrenComponent>

      <TooltipStyled
        id={id}
        position={position}
        place={place}
        type={type}
        effect={effect}
        multiline={multiline}
        html={html}
        getContent={getContent}
        delayHide={delayHide}
        delayShow={delayShow}
        delayUpdate={delayUpdate}
        border={border}
        borderColor={borderColor}
        textColor={textColor}
        backgroundColor={backgroundColor}
        arrowColor={arrowColor}
        afterShow={afterShow}
        afterHide={afterHide}
        disable={disable}
        scrollHide={scrollHide}
        wrapper={wrapper}
        event={event}
        eventOff={eventOff}
        offset={offset}
        clickable={clickable}
        className={className}
        overridePosition={({ left, top }, _, __, node, place, ___, effect) => {
          if (position !== 'start' || effect !== 'solid') {
            return { left, top }
          }         

          if (place === 'top' || place === 'bottom') {
            // @ts-ignore
            const nodeWidth = node.clientWidth || 0;

            if (nodeWidth < childrenComponentWidth) {
              const offsetWidth = Math.round((childrenComponentWidth - nodeWidth) / 2)
              return { left: left - offsetWidth, top }
            } 
            
            if (nodeWidth > childrenComponentWidth) {
              const offsetWidth = Math.round((nodeWidth - childrenComponentWidth) / 2)
              return { left: left + offsetWidth, top }
            }
          }

          return { left, top }
        }}
      />
    </>
  )
}

export { Tooltip, TooltipProps };
