import React, { FC, memo, useState } from 'react';
import {
  StyledSwitcherCheckbox,
  StyledSwitcherElementCircle,
  StyledSwitcherElementEllipse,
  StyledSwitcherWrapper,
} from './styles';

export type SwitcherProps = {
  value?: boolean;
  onChange?: (value: boolean) => void;
  isBlocked?: boolean;
  isLarge?: boolean;
};

const Switcher: FC<SwitcherProps> = ({
  onChange,
  value,
  isBlocked,
  isLarge,
}) => {
  const [isActive, setIsActive] = useState<boolean>(value || false);

  const handleChange = (): void => {
    if (!isBlocked) {
      setIsActive(!isActive);
      onChange?.(!isActive);
    }
  };

  return (
    <StyledSwitcherWrapper onClick={handleChange}>
      <StyledSwitcherCheckbox
        disabled={isBlocked}
        type={'checkbox'}
        checked={isActive}
        onChange={handleChange}
      />
      <StyledSwitcherElementEllipse
        $isActive={isActive}
        $isBlocked={isBlocked}
        $isLarge={isLarge}
      >
        <StyledSwitcherElementCircle />
      </StyledSwitcherElementEllipse>
    </StyledSwitcherWrapper>
  );
};

export default memo(Switcher);
