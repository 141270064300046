import styled from 'styled-components';
import { Colors } from '../../../../configs/Colors';

export type PropsStyledModal = {
  $size?: EModalSize;
  $modalPaddings?: string;
};

export enum EModalSize {
  Small = '417px',
  Medium = '440px',
  Large = '500px',
}

export const ModalWrapper = styled.div`
  position: relative;
`;

export const LabelTitle = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  /* or 125% */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.15px;
  margin: 0;
  margin-bottom: 36px;
  color: ${Colors.black};
  white-space: pre-wrap;
`;

export const MarkImg = styled.img`
  width: 50px;
  height: 47px;
  margin-bottom: 35px;
  margin-top: 18px;
`;

export const ButtonActionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  text-align: center;
  width: 100%;

  > button {
    display: inline-block;
  }
`;

export const Overlay = styled.div`
  position: fixed;
  min-height: 100vh;
  min-width: 100vw;
  top: 0;
  left: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background: rgba(21, 31, 50, 0.3);
  backdrop-filter: blur(8px);
  z-index: 10000;
`;

export const Modal = styled.div<PropsStyledModal>`
  background: #ffffff;
  max-width: ${({ $size }) => $size || EModalSize.Small};
  max-height: 608px;
  width: 100%;
  padding: ${({ $modalPaddings }) =>
    $modalPaddings ? $modalPaddings : '60px'};
  box-shadow: 0 2px 24px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 16px;
  align-items: center;

  z-index: 10001;

  @media (max-width: 440px) {
    border-radius: 0;
  }
`;
