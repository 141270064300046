import styled from 'styled-components';

export const ButtonActionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  text-align: center;
  width: 100%;

  > button {
    display: inline-block;
  }
`;

const Styled = {
  ButtonActionWrapper,
};

export default Styled;
