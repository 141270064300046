import React, { FC } from 'react';
import { ChipStyledProps, IconWrapper, Wrapper } from './style';
import { IconNames } from '../Icon/Icon.enum';
import { Icon } from '../Icon';

type ChipProps = {
  icon?: IconNames;
  dataTestId?: string;
  className?: string;
} & ChipStyledProps;

const Chip: FC<ChipProps> = ({
  size = 'medium',
  color = 'default',
  icon,
  children,
  dataTestId,
  className
}) => {
  return (
    <Wrapper className={className} data-test-id={dataTestId} color={color} size={size}>
      {icon && <IconWrapper>
          <Icon size={size} icon={icon} />
      </IconWrapper>}
      {children}
    </Wrapper>
  )
}

export {Chip, ChipProps};
