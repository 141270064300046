import React, { FC, memo } from 'react';

import { Button, ButtonProps } from '../../../../Button';
import withCustomModalComponent from '../../../../shared/utils/withCustomModalComponent';
import SuccessSvg from '../../../../shared/assets/icons/success.svg';
import WarningSvg from '../../../../shared/assets/icons/warning.svg';

import * as Styled from './styles';

export enum EModalType {
  Warning = 'WARNING',
  Success = 'SUCCESS',
  Custom = 'CUSTOM',
}

export type TModalButton = {
  title: string;
  color?: ButtonProps['color'];
  disabled?: boolean;
  handler?: () => void;
  dataTestId?: string;
  preventClose?: boolean;
};

export type TModalConfig = {
  name: string;
  title: string;
  type: EModalType;
  id: string;
  successButton?: TModalButton;
  denyButton?: TModalButton;
  dataTestId?: string;
  children?: FC;
  styledProps?: Styled.PropsStyledModal;
};

export type ModalProps = {
  modal: TModalConfig;
  closeModalHandler: () => void;
} & Styled.PropsStyledModal;

const Modal: FC<ModalProps> = ({
  modal: {
    title,
    type,
    successButton,
    denyButton,
    dataTestId,
    children,
    name,
    styledProps,
  },
  closeModalHandler,
  ...styled
}) => {
  const denyButtonHandler = async (): Promise<void> => {
    await denyButton?.handler?.();
    if (!denyButton?.preventClose) closeModalHandler();
  };

  const successButtonHandler = async (): Promise<void> => {
    await successButton?.handler?.();
    if (!successButton?.preventClose) closeModalHandler();
  };

  return (
    <Styled.Overlay>
      <Styled.Modal
        {...styled}
        {...styledProps}
        data-test-id={dataTestId ? dataTestId : `${name}-modal`}
      >
        {type !== EModalType.Custom ? (
          type === EModalType.Success ? (
            <Styled.MarkImg src={SuccessSvg} />
          ) : (
            <Styled.MarkImg src={WarningSvg} />
          )
        ) : null}
        {title ? <Styled.LabelTitle>{title}</Styled.LabelTitle> : null}
        {type === EModalType.Custom && children
          ? withCustomModalComponent(children)
          : null}
        <Styled.ButtonActionWrapper>
          {denyButton && (
            <Button
              color={denyButton?.color || 'default'}
              type={'button'}
              disabled={denyButton?.disabled}
              dataTestId={
                denyButton.dataTestId
                  ? denyButton.dataTestId
                  : `${name}-modal-deny-button`
              }
              onClick={denyButtonHandler}
            >
              {denyButton.title}
            </Button>
          )}
          {successButton && (
            <Button
              color={successButton?.color || 'primary'}
              type={'button'}
              disabled={successButton?.disabled}
              dataTestId={
                successButton.dataTestId
                  ? successButton.dataTestId
                  : `${name}-modal-accept-button`
              }
              onClick={successButtonHandler}
            >
              {successButton.title}
            </Button>
          )}
        </Styled.ButtonActionWrapper>
      </Styled.Modal>
    </Styled.Overlay>
  );
};

export default memo(Modal);
